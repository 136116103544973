<template>
  <div>
    <div v-if="currentLocale === 'es'">
      <p>Su privacidad es importante para nosotros. Esta declaraci&oacute;n de privacidad explica qu&eacute; datos personales recogemos de nuestros usuarios y c&oacute;mo los utilizamos. Le recomendamos que lea atentamente estas condiciones antes de facilitar sus datos personales en este sitio web. Los mayores de trece a&ntilde;os pueden registrarse en https://app.nectios.com/ como usuarios sin el consentimiento previo de sus padres o tutores.</p>
<p>En el caso de los menores de trece a&ntilde;os, se requiere el consentimiento de sus padres o tutores para el tratamiento de sus datos personales. En ning&uacute;n caso se recoger&aacute;n del menor datos relativos a la situaci&oacute;n profesional, econ&oacute;mica o a la intimidad de otros miembros de la familia, sin su consentimiento.</p>
<p>Si eres menor de trece a&ntilde;os y has accedido a este sitio web sin avisar a tus padres, no debes registrarte como usuario.</p>
<p>Este sitio web respeta y cuida los datos personales de los usuarios. Como usuario debes saber que tus derechos est&aacute;n garantizados. Nos hemos esforzado por crear un espacio seguro y fiable y por eso queremos compartir nuestros principios respecto a tu privacidad:</p>
<ul>
<li>Nunca solicitamos informaci&oacute;n personal a menos que sea realmente necesaria para proporcionarle los servicios que requiere.</li>
<li>Nunca compartimos informaci&oacute;n personal de nuestros usuarios con nadie, excepto para cumplir con la ley o en caso de que tengamos su autorizaci&oacute;n expresa.</li>
<li>Nunca utilizaremos sus datos personales para un fin distinto al expresado en esta pol&iacute;tica de privacidad.</li>
</ul>
<p>Hay que tener en cuenta que esta Pol&iacute;tica de Privacidad podr&iacute;a variar en funci&oacute;n de exigencias legislativas o de autorregulaci&oacute;n, por lo que se recomienda a los usuarios que la visiten peri&oacute;dicamente. Ser&aacute; aplicable en el caso de que los usuarios decidan rellenar un formulario de cualquiera de sus formas de contacto donde se recojan datos personales.</p>
<p>COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) ha adecuado esta web a las exigencias de la Ley Org&aacute;nica 15/1999, de 13 de diciembre, de Protecci&oacute;n de Datos de Car&aacute;cter Personal (LOPD), y al Real Decreto 1720/2007, de 21 de diciembre, conocido como el Reglamento de Desarrollo de la LOPD. Tambi&eacute;n cumple con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protecci&oacute;n de las personas f&iacute;sicas (RGPD), as&iacute; como con la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y Comercio Electr&oacute;nico (LSSICE o LSSI).</p>
<p>A efectos de lo previsto en el Reglamento General de Protecci&oacute;n de Datos antes citado, los datos personales que nos env&iacute;es a trav&eacute;s de los formularios de la web, recibir&aacute;n el tratamiento de datos de "Usuarios de la web y suscriptores". Para el tratamiento de datos de nuestros usuarios, implementamos todas las medidas t&eacute;cnicas y organizativas de seguridad establecidas en la legislaci&oacute;n vigente.</p>
<h2>Principios que aplicaremos a su informaci&oacute;n personal</h2>
<p>En el tratamiento de sus datos personales, aplicaremos los siguientes principios que cumplen con los requisitos del nuevo reglamento europeo de protecci&oacute;n de datos:</p>
<ul>
<li>Principio de legalidad, lealtad y transparencia: Siempre requeriremos su consentimiento para el tratamiento de sus datos personales para una o varias finalidades espec&iacute;ficas de las que le informaremos previamente con absoluta transparencia.</li>
<li>Principio de minimizaci&oacute;n de datos: S&oacute;lo solicitaremos los datos estrictamente necesarios en relaci&oacute;n con los fines para los que los requerimos. El m&iacute;nimo posible.</li>
<li>Principio de limitaci&oacute;n del plazo de conservaci&oacute;n: los datos se conservar&aacute;n durante no m&aacute;s tiempo del necesario para los fines del tratamiento, dependiendo de la finalidad, le informaremos del plazo de conservaci&oacute;n correspondiente, en el caso de las suscripciones, revisaremos peri&oacute;dicamente nuestras listas y eliminaremos aquellos registros inactivos durante un tiempo considerable.</li>
<li>Principio de integridad y confidencialidad: Sus datos ser&aacute;n tratados de forma que se garantice la adecuada seguridad de los datos personales y se garantice la confidencialidad. Debe saber que tomamos todas las precauciones necesarias para evitar el acceso no autorizado o el uso indebido de los datos de nuestros usuarios por parte de terceros.</li>
</ul>
<h2>&iquest;C&oacute;mo hemos obtenido sus datos?</h2>
<p>Los datos personales que tratamos en COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) provienen de:</p>
<ul>
<li>Formulario de contacto</li>
<li>Formulario de suscripci&oacute;n</li>
<li>Comentarios del blog</li>
<li>Descargar formulario</li>
<li>Formulario de inscripci&oacute;n</li>
<li>Formulario de inscripci&oacute;n al webinar</li>
</ul>
<ul>
<li>
<h2>&iquest;Cu&aacute;les son sus derechos cuando nos facilita sus datos?</h2>
<p>Cualquier persona tiene derecho a obtener confirmaci&oacute;n sobre si en COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) estamos tratando datos personales que me concierne, o no.</p>
<p>Los interesados tienen derecho a:</p>
<ul>
<li>Solicitar el acceso a los datos personales del interesado</li>
<li>Solicitar su rectificaci&oacute;n o supresi&oacute;n</li>
<li>Solicitar la limitaci&oacute;n de su tratamiento</li>
<li>Objeci&oacute;n al tratamiento</li>
<li>Solicitar la portabilidad de los datos</li>
</ul>
<p>Los interesados podr&aacute;n acceder a sus datos personales, as&iacute; como solicitar la rectificaci&oacute;n de los datos inexactos o, en su caso, solicitar su supresi&oacute;n cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. En determinadas circunstancias, los interesados podr&aacute;n solicitar la limitaci&oacute;n del tratamiento de sus datos, en cuyo caso s&oacute;lo los conservar&eacute; para el ejercicio o la defensa de reclamaciones.</p>
<p>En determinadas circunstancias y por motivos relacionados con su situaci&oacute;n particular, los interesados podr&aacute;n oponerse al tratamiento de sus datos. COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) dejar&aacute; de tratar los datos, salvo por motivos leg&iacute;timos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. Como interesado, tiene derecho a recibir los datos personales que le conciernen, que nos ha facilitado y en un formato estructurado, de uso com&uacute;n y lectura mec&aacute;nica, y a transmitirlos a otro responsable del tratamiento cuando:</p>
<ul>
<li>El tratamiento se basa en el consentimiento</li>
<li>Los datos han sido facilitados por el interesado.</li>
<li>El tratamiento se realiza por medios automatizados.</li>
</ul>
<p>Al ejercer su derecho a la portabilidad de los datos, tendr&aacute; derecho a que los datos personales se transmitan directamente de responsable a responsable cuando sea t&eacute;cnicamente posible.</p>
<p>Los interesados tambi&eacute;n tendr&aacute;n derecho a la tutela judicial efectiva y a presentar una reclamaci&oacute;n ante la autoridad de control, en este caso, la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos, si consideran que el tratamiento de datos personales que les afecta vulnera el Reglamento.</p>
<h2>&iquest;Con qu&eacute; finalidad tratamos sus datos personales?</h2>
<p>Cuando un usuario se conecta con esta web, por ejemplo para comentar un post, mandar un correo al titular, suscribirse o realizar alguna contrataci&oacute;n, est&aacute; facilitando informaci&oacute;n de car&aacute;cter personal de la que es responsable COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios). Esa informaci&oacute;n puede incluir datos personales como su direcci&oacute;n IP, nombre, direcci&oacute;n f&iacute;sica, direcci&oacute;n de correo electr&oacute;nico, n&uacute;mero de tel&eacute;fono y otra informaci&oacute;n. Al proporcionar esta informaci&oacute;n, el usuario da su consentimiento para que su informaci&oacute;n sea recopilada, utilizada, gestionada y almacenada por https://app.nectios.com/, s&oacute;lo como se describe en el Aviso Legal y en esta Pol&iacute;tica de Privacidad.</p>
<p>En COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) existen diferentes sistemas de captura de informaci&oacute;n personal y trato la informaci&oacute;n que me facilitan las personas interesadas con el siguiente fin por cada sistema de captura (formularios):</p>
<ul>
<li>Formulario de contacto: Solicitamos los siguientes datos personales: Nombre, Email, para responder a los requerimientos de los usuarios de https://nectios.com. Por ejemplo, podemos utilizar estos datos para atender su solicitud y responder a las dudas, quejas, comentarios o inquietudes que pueda tener en relaci&oacute;n con la informaci&oacute;n incluida en la web, los servicios prestados a trav&eacute;s de la misma, el tratamiento de sus datos personales, cuestiones relativas a los textos legales incluidos en la web, as&iacute; como cualquier otra consulta que pueda tener y que no est&eacute; sujeta a las condiciones de contrataci&oacute;n.</li>
<li>Formulario de suscripci&oacute;n a contenidos: En este caso, solicitamos los siguientes datos personales: Nombre, Email, para gestionar la lista de suscriptores, enviar boletines, promociones y ofertas especiales, facilitados por el usuario al realizar la suscripci&oacute;n. Dentro de la web existen varios formularios para activar la suscripci&oacute;n. Los boletines electr&oacute;nicos o newsletters son gestionados por Sengrid. Le informo que los datos que nos facilite estar&aacute;n ubicados en los servidores de Sengrid (proveedor de COPERNIC TECHNOLOGIES S.L.).</li>
<li>Formulario de descarga: En este sitio web puede descargar diferentes contenidos que se incorporan peri&oacute;dicamente. En este caso, solicitamos los siguientes datos personales: Nombre, Email, para activar el formulario de suscripci&oacute;n. Sus datos se utilizan para los fines indicados para los suscriptores.</li>
<li>Formulario de inscripci&oacute;n: En este caso, solicitamos los siguientes datos personales: Nombre, Email, para que puedas crear una cuenta en nuestra aplicaci&oacute;n https://app.nectios.com/ y tener acceso a sus funcionalidades.</li>
</ul>
<p>Hay otros fines para los que tratamos sus datos personales:</p>
<ul>
<li>Garantizar el cumplimiento de las condiciones de uso y de la legislaci&oacute;n aplicable. Esto puede incluir el desarrollo de herramientas y algoritmos que ayuden a este sitio web a garantizar la confidencialidad de los datos personales que recoge.</li>
<li>Para apoyar y mejorar los servicios ofrecidos por este sitio web.</li>
<li>Tambi&eacute;n se recogen otros datos no identificativos que se obtienen a trav&eacute;s de algunas cookies que se descargan en el ordenador del usuario al navegar por esta web, que se detallan en la pol&iacute;tica de cookies.</li>
<li>Gestionar las redes sociales. COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) puede tener presencia en redes sociales. El tratamiento de los datos que se lleve a cabo de las personas que se hagan seguidoras en las redes sociales de las p&aacute;ginas oficiales de https://www.connectingatartup.com, se regir&aacute; por este apartado. As&iacute; como por aquellas condiciones de uso, pol&iacute;ticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y aceptadas previamente por el usuario de COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios). Tratar&aacute; sus datos con las finalidades de administrar correctamente su presencia en la red social, informando de actividades, productos o servicios de COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios). As&iacute; como para cualquier otra finalidad que las normativas de las redes sociales permitan. En ning&uacute;n caso utilizaremos los perfiles de los seguidores en redes sociales para enviar publicidad de forma individual.</li>
</ul>
<p>De acuerdo con lo establecido en el reglamento general de protecci&oacute;n de datos europeo (RGPD) 2016/679, COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) con domicilio en ESPA&Ntilde;A. 12400 Segorbe, Castell&oacute;n, ser&aacute; responsable del tratamiento de los datos correspondientes a los Usuarios de la web y suscriptores.</p>
<p>COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios), no vende, alquila ni cede datos de car&aacute;cter personal que puedan identificar al usuario, ni lo har&aacute; en el futuro, a terceros sin el consentimiento previo. No obstante, en algunos casos se pueden realizar colaboraciones con otros profesionales, en esos casos, se requerir&aacute; el consentimiento de los usuarios informando sobre la identidad del colaborador y la finalidad de la colaboraci&oacute;n. Siempre se realizar&aacute; con los m&aacute;s estrictos est&aacute;ndares de seguridad.</p>
</li>
</ul>
<h2>Remarketing</h2>
<p>La funci&oacute;n de remarketing nos permite llegar a las personas que han visitado previamente https://nectios.com y asociar un p&uacute;blico espec&iacute;fico con un mensaje concreto. El remarketing es un m&eacute;todo para conseguir que los usuarios que han visitado nuestro sitio vuelvan a hacerlo.</p>
<p>Como usuario de https://app.nectios.com/ te informo que estoy recogiendo informaci&oacute;n para esta funci&oacute;n de remarketing en https://nectios.com.</p>
<p>La informaci&oacute;n que recopilamos gracias a esta funci&oacute;n es recogida por las cookies de Facebook, Google Ads, LinkedIn Ads. Puedes conocer los datos que recogen estas cookies en las siguientes pol&iacute;ticas de privacidad de cada servicio:</p>
<ul>
<li>Facebook</li>
<li>Anuncios de Google</li>
<li>Anuncios en LinkedIn</li>
</ul>
<p>Si no desea que su informaci&oacute;n sea recopilada por estas cookies, puede inhabilitar el uso de cookies por parte de Google a trav&eacute;s de la configuraci&oacute;n de anuncios de Google. Tambi&eacute;n puede inhabilitar el uso de cookies de un proveedor externo a trav&eacute;s de la p&aacute;gina de inhabilitaci&oacute;n de la Network Advertising Initiative.</p>
<p>Este tipo de servicio permite interactuar con las redes sociales u otras plataformas externas directamente desde las p&aacute;ginas de este sitio web. Las interacciones y la informaci&oacute;n obtenida por este sitio web estar&aacute;n siempre sujetas a la configuraci&oacute;n de privacidad del usuario en cada red social. En el caso de que se instale un servicio que permita la interacci&oacute;n con las redes sociales, es posible que, aunque los usuarios no utilicen el servicio, &eacute;ste recoja datos de tr&aacute;fico web relacionados con las p&aacute;ginas en las que est&aacute;n instalados.</p>
<h2>Legitimaci&oacute;n para el tratamiento de sus datos</h2>
<p>La base legal para el tratamiento de sus datos es: el consentimiento.</p>
<p>Para contactar o hacer comentarios en este sitio web, se requiere el consentimiento con esta pol&iacute;tica de privacidad.</p>
<p>La oferta prospectiva o comercial de productos y servicios se basa en el consentimiento que se solicita, sin que en ning&uacute;n caso la retirada de dicho consentimiento condicione la ejecuci&oacute;n del contrato de suscripci&oacute;n.</p>
<p>categor&iacute;a de datos<br />Las categor&iacute;as de datos que se tratan son datos identificativos. Las categor&iacute;as de datos especialmente protegidas no se procesan.</p>
<p>&iquest;Cu&aacute;nto tiempo conservaremos sus datos?<br />Los datos personales proporcionados se conservar&aacute;n:</p>
<p>&iquest;A qu&eacute; destinatarios se comunicar&aacute;n sus datos?<br />Muchas de las herramientas que utilizamos para gestionar sus datos son contratadas por terceros.</p>
<p>Para prestar servicios estrictamente necesarios para el desarrollo de la actividad, https://app.nectios.com, comparte datos con los siguientes prestadores bajo sus correspondientes condiciones de privacidad:</p>
<p>Google Analytics: es un servicio anal&iacute;tico de web prestado por Google, Inc. una compa&ntilde;&iacute;a de Delaware con sede en 1600 Amphitheater Parkway, Mountain View (California), CA 94043, Estados Unidos ("Google"). Google Analytics utiliza "cookies", que son archivos de texto ubicados en su ordenador, para ayudar a https://www.connectingstartup.com a analizar el uso que hacen los usuarios del sitio web. La informaci&oacute;n que genera la cookie acerca de su uso de https://connectingstartup.com (incluyendo su direcci&oacute;n IP) ser&aacute; directamente transmitida y archivada por Google en los servidores de Estados Unidos.</p>
<p>Marketing por correo electr&oacute;nico: Sengrid. M&aacute;s informaci&oacute;n en: WWW.SENGRID.COM</p>
<h2>Navegaci&oacute;n</h2>
<p>Al navegar por https://nectios.com se pueden recoger datos no identificativos, que pueden incluir direcciones IP, ubicaci&oacute;n geogr&aacute;fica (aproximadamente), un registro de c&oacute;mo se utilizan los servicios y sitios, y otros datos que no pueden ser utilizados para identificar al usuario. Entre los datos no identificables tambi&eacute;n est&aacute;n los relacionados con sus h&aacute;bitos de navegaci&oacute;n a trav&eacute;s de servicios de terceros. Este sitio web utiliza los siguientes servicios de an&aacute;lisis de terceros:</p>
<ul>
<li>Google Analytics</li>
<li>Facebook en el interior</li>
<li>Facebook-pixel</li>
<li>InsightTag</li>
</ul>
<p>Utilizamos esta informaci&oacute;n para analizar tendencias, administrar el sitio, rastrear los movimientos de los usuarios en el sitio y reunir informaci&oacute;n demogr&aacute;fica sobre nuestra base de usuarios en su conjunto.</p>
<h2>Secreto y seguridad de los datos</h2>
<p>COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) se compromete a utilizar y tratar los datos personales de los usuarios, respetando su confidencialidad y utiliz&aacute;ndolos de acuerdo con su finalidad, as&iacute; como a dar cumplimiento a su obligaci&oacute;n de guardarlos y adaptar todas las medidas para evitar la alteraci&oacute;n, p&eacute;rdida, tratamiento o acceso no autorizado, de conformidad con lo establecido en la normativa vigente en materia de protecci&oacute;n de datos.</p>
<p>Este sitio web incluye un certificado SSL. Se trata de un protocolo de seguridad que garantiza que sus datos viajan de forma completa y segura, es decir, que la transmisi&oacute;n de datos entre un servidor web y el usuario, y en retroalimentaci&oacute;n, est&aacute; totalmente encriptada o codificada. COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) no puede garantizar la absoluta inexpugnabilidad de la red Internet y por tanto la violaci&oacute;n de los datos mediante accesos fraudulentos a ellos por parte de terceros.</p>
<p>En cuanto a la confidencialidad del tratamiento, COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) se asegurar&aacute; de que cualquier persona autorizada por COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) para el tratamiento de los datos del cliente (incluyendo su personal, colaboradores y proveedores), estar&aacute; bajo la correspondiente obligaci&oacute;n de confidencialidad (ya sea un deber contractual o legal). Cuando se produzca un incidente de seguridad, la realizaci&oacute;n de COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios), notificar&aacute; al Cliente sin demora indebida y proporcionar&aacute; informaci&oacute;n oportuna relacionada con el Incidente de Seguridad tal y como se conozca o cuando el Cliente lo solicite razonablemente.</p>
<h2>Exactitud y veracidad de los datos</h2>
<p>Como usuario, eres el &uacute;nico responsable de la veracidad y correcci&oacute;n de los datos que env&iacute;es a https://app.nectios.com, exonerando a COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios), de cualquier responsabilidad al respecto.</p>
<p>Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El usuario se compromete a facilitar informaci&oacute;n completa y correcta en el formulario de contacto o suscripci&oacute;n.</p>
<h2>Aceptaci&oacute;n y consentimiento</h2>
<p>El usuario declara haber sido informado de las condiciones sobre protecci&oacute;n de datos de car&aacute;cter personal, aceptando y consintiendo el tratamiento de los mismos por parte de COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) en la forma y para las finalidades indicadas en esta pol&iacute;tica de privacidad.</p>
<h2>Revocabilidad</h2>
<p>El consentimiento prestado, tanto para el tratamiento como para la cesi&oacute;n de los datos de los interesados, es revocable en cualquier momento comunic&aacute;ndolo a COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) en los t&eacute;rminos establecidos en esta Pol&iacute;tica para el ejercicio de los derechos ARCO. Esta revocaci&oacute;n no tendr&aacute; en ning&uacute;n caso car&aacute;cter retroactivo.</p>
<h2>Cambios en la pol&iacute;tica de privacidad</h2>
<p>COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) se reserva el derecho a modificar la presente pol&iacute;tica para adaptarla a novedades legislativas o jurisprudenciales, as&iacute; como a pr&aacute;cticas de la industria. En dichos supuestos, COPERNIC TECHNOLOGIES S.L. (tambi&eacute;n Nectios) anunciar&aacute; en esta p&aacute;gina los cambios introducidos con razonable antelaci&oacute;n a su puesta en pr&aacute;ctica.</p>
<h2>Mensajer&iacute;a comercial</h2>
<p>De acuerdo con la LSSICE, https://app.nectios.com no realiza pr&aacute;cticas de SPAM, por lo que no env&iacute;a correos electr&oacute;nicos comerciales que no hayan sido previamente solicitados o autorizados por el usuario. En consecuencia, en cada uno de los formularios de la web, el usuario tiene la posibilidad de dar su consentimiento expreso para recibir el bolet&iacute;n, con independencia de la informaci&oacute;n comercial que se solicite espec&iacute;ficamente. Conforme a lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Informaci&oacute;n y de comercio electr&oacute;nico, https://connectingstartup.com se compromete a no enviar comunicaciones de car&aacute;cter comercial sin identificarlas debidamente.</p>
<p>Documento revisado el 15-04-2022.</p>  
</div>
  <div v-if="currentLocale === 'ca'"><p>La teva privacitat &eacute;s important per a nosaltres. Aquesta declaraci&oacute; de privadesa explica quines dades personals recollim dels nostres usuaris i com les utilitzem. Us animem a llegir atentament aquests termes abans de proporcionar les vostres dades personals en aquest lloc web. Els majors de tretze anys podran donar-se d'alta en https://app.nectios.com/ com a usuaris sense el consentiment previ dels seus pares o tutors.</p>
<p>En el cas de menors de tretze anys, es requereix el consentiment dels seus pares o tutors per al tractament de les seves dades personals. En cap cas es recolliran dades relatives a la situaci&oacute; professional, econ&ograve;mica o a la intimitat d'altres familiars del menor, sense el seu consentiment.</p>
<p>Si ets menor de tretze anys i has accedit a aquest lloc web sense notificar-ho als teus pares, no t'has de registrar com a usuari.</p>
<p>Aquest lloc web respecta i t&eacute; cura de les dades personals dels usuaris. Com a usuari has de saber que els teus drets estan garantits. Hem fet un esfor&ccedil; per crear un espai segur i fiable i per aix&ograve; volem compartir els nostres principis pel que fa a la teva privacitat:</p>
<ul>
<li>Mai sol&middot;licitem informaci&oacute; personal a menys que sigui realment necessari per proporcionar-li els serveis que requereix.</li>
<li>Mai compartim informaci&oacute; personal dels nostres usuaris amb ning&uacute;, excepte per complir amb la llei o en cas que tinguem la seva autoritzaci&oacute; expressa.</li>
<li>Mai utilitzarem les seves dades personals per a una finalitat diferent de l'expressada en aquesta pol&iacute;tica de privacitat.</li>
</ul>
<p>Cal destacar que aquesta Pol&iacute;tica de Privacitat podria variar en funci&oacute; dels requisits legislatius o d'autoregulaci&oacute;, per la qual cosa s'aconsella als usuaris que la visitin peri&ograve;dicament. Ser&agrave; aplicable en el cas que els usuaris decideixin emplenar un formulari de qualsevol dels seus formularis de contacte on es recullin dades de car&agrave;cter personal.</p>
<p>COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) ha adaptat aquest lloc web a les exig&egrave;ncies de la Llei Org&agrave;nica 15/1999, de 13 de desembre, de Protecci&oacute; de Dades de Car&agrave;cter Personal (LOPD), i el Reial decret 1720/2007, de 21 de desembre, conegut com a Reglament de Desenvolupament de la LOPD. Tamb&eacute; compleix amb el Reglament (UE) 2016/679 del Parlament Europeu i del Consell de 27 d'abril de 2016 relatiu a la protecci&oacute; de les persones f&iacute;siques (RGPD), aix&iacute; com amb la Llei 34/2002, d'11 de juliol, de Serveis de la Societat de la Informaci&oacute; i de Comer&ccedil; Electr&ograve;nic (LSSICE o LSSI).</p>
<p>A efectos de lo previsto en el Reglamento General de Protecci&oacute;n de Datos antes citado, los datos personales que nos env&iacute;es a trav&eacute;s de los formularios de la web, recibir&aacute;n el tratamiento de datos de "Usuarios de la web y suscriptores". Para el tratamiento de datos de nuestros usuarios, implementamos todas las medidas t&eacute;cnicas y organizativas de seguridad establecidas en la legislaci&oacute;n vigente.</p>
<h2>Principis que aplicarem a la seva informaci&oacute; personal</h2>
<p>En el tractament de les seves dades personals, aplicarem els seg&uuml;ents principis que compleixen amb els requisits de la nova normativa europea de protecci&oacute; de dades:</p>
<ul>
<li>Principi de licitud, lleialtat i transpar&egrave;ncia: Sempre requerirem el seu consentiment per al tractament de les seves dades personals per a una o m&eacute;s finalitats espec&iacute;fiques de les que pr&egrave;viament l'informarem amb absoluta transpar&egrave;ncia.</li>
<li>Principi de minimitzaci&oacute; de dades: Nom&eacute;s sol&middot;licitarem dades estrictament necess&agrave;ries en relaci&oacute; amb les finalitats per a les quals ho requerim. El m&iacute;nim possible.</li>
<li>Principi de limitaci&oacute; del termini de conservaci&oacute;: les dades es conservaran durant no m&eacute;s temps del necessari per a les finalitats del tractament, en funci&oacute; de la finalitat, l'informarem del termini de conservaci&oacute; corresponent, en el cas de subscripcions, revisarem peri&ograve;dicament les nostres llistes i eliminarem aquests registres inactius durant un temps considerable.</li>
<li>Principi d'integritat i confidencialitat: Les seves dades seran tractades de manera que es garanteixi una seguretat adequada de les dades personals i es garanteixi la confidencialitat. Vost&egrave; ha de saber que prenem totes les precaucions necess&agrave;ries per evitar l'acc&eacute;s no autoritzat o l'&uacute;s indegut de les dades dels nostres usuaris per part de tercers.</li>
</ul>
<h2>Com hem obtingut les teves dades?</h2>
<p>Les dades personals que tractem a COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) procedeixen de:</p>
<ul>
<li>Formulari de contacte</li>
<li>Formulari de subscripci&oacute;</li>
<li>Comentaris del blog</li>
<li>Descarrega el formulari</li>
<li>Formulari d'inscripci&oacute;</li>
<li>Formulari d'inscripci&oacute; al webinar</li>
</ul>
<h2>Quins s&oacute;n els seus drets quan ens facilita les seves dades?</h2>
<p>Qualsevol persona t&eacute; dret a obtenir confirmaci&oacute; sobre si COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) estem tractant dades personals que em concerneixin, o no.</p>
<p>Les persones interessades tenen dret a:</p>
<ul>
<li>Sol&middot;licitar l'acc&eacute;s a les dades personals relatives a l'interessat</li>
<li>Sol&middot;licitar la seva rectificaci&oacute; o supressi&oacute;</li>
<li>Sol&middot;licita la limitaci&oacute; del teu tractament</li>
<li>Objecte de tractament</li>
<li>Sol&middot;licita la portabilitat de les dades</li>
</ul>
<p>Els interessats podran accedir a les seves dades personals, aix&iacute; com sol&middot;licitar la rectificaci&oacute; de les dades inexactes o, si escau, sol&middot;licitar la seva supressi&oacute; quan, entre altres motius, les dades ja no siguin necess&agrave;ries per a les finalitats per a les quals van ser recollides. En determinades circumst&agrave;ncies, els interessats podran sol&middot;licitar la limitaci&oacute; del tractament de les seves dades, en aquest cas &uacute;nicament els conservar&eacute; per a l'exercici o la defensa de reclamacions.</p>
<p>En determinades circumst&agrave;ncies i per motius relacionats amb la seva situaci&oacute; particular, els interessats podran oposar-se al tractament de les seves dades. COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) deixar&agrave; de tractar les dades, excepte per motius leg&iacute;tims imperiosos, o l'exercici o la defensa de possibles reclamacions. Com a interessat, tens dret a rebre les dades personals que et concerneixin, que ens hagis facilitat i en un format estructurat, d'&uacute;s com&uacute; i lectura mec&agrave;nica, i a transmetre'ls a un altre responsable del tractament quan:</p>
<ul>
<li>El tractament es basa en el consentiment</li>
<li>Les dades han estat facilitades per la persona interessada.</li>
<li>El tractament es realitza per mitjans automatitzats.</li>
</ul>
<p>En exercir el seu dret a la portabilitat de les dades, tindr&agrave; dret a que les dades personals es transmetin directament del controlador a un responsable quan sigui t&egrave;cnicament possible.</p>
<p>Els interessats tamb&eacute; tindran dret a la tutela judicial efectiva i a presentar una reclamaci&oacute; davant l'autoritat de control, en aquest cas, l'Ag&egrave;ncia Espanyola de Protecci&oacute; de Dades, si consideren que el tractament de dades personals que els concerneix infringeix el Reglament.</p>
<h2>Amb quina finalitat tractem les seves dades personals?</h2>
<p>Quan un usuari es connecta a aquest lloc web, per exemple per comentar una publicaci&oacute;, enviar un correu electr&ograve;nic al titular, subscriure's o realitzar un contracte, est&agrave; proporcionant informaci&oacute; personal de la qual COPERNIC TECHNOLOGIES S.L. &eacute;s responsable. (Tamb&eacute; Nectios). Aquesta informaci&oacute; pot incloure dades personals com la vostra adre&ccedil;a IP, nom, adre&ccedil;a f&iacute;sica, adre&ccedil;a de correu electr&ograve;nic, n&uacute;mero de tel&egrave;fon i altra informaci&oacute;. En facilitar aquesta informaci&oacute;, l'usuari d&oacute;na el seu consentiment perqu&egrave; la seva informaci&oacute; sigui recollida, utilitzada, gestionada i emmagatzemada per https://app.nectios.com/, &uacute;nicament tal com es descriu en l'Av&iacute;s Legal i en aquesta Pol&iacute;tica de Privacitat.</p>
<p>A COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) existeixen diferents sistemes de captaci&oacute; d'informaci&oacute; personal i tractem la informaci&oacute; facilitada per les persones interessades amb la seg&uuml;ent finalitat per a cada sistema de captura (formularis):</p>
<ul>
<li>Formulari de contacte: Sol&middot;licitem les seg&uuml;ents dades personals: Nom, Correu electr&ograve;nic, per respondre als requeriments dels usuaris de https://nectios.com. Per exemple, podem utilitzar aquestes dades per respondre a la seva sol&middot;licitud i respondre a qualsevol dubte, queixa, comentari o inquietud que pugui tenir respecte a la informaci&oacute; inclosa a la web, els serveis prestats a trav&eacute;s de la web, el tractament de les seves dades personals, preguntes relatives als textos legals inclosos en el lloc web, aix&iacute; com qualsevol altra consulta que pugui tenir que no estiguin subjectes a les condicions de contractaci&oacute;.</li>
<li>Formulari de subscripci&oacute; al contingut: En aquest cas, sol&middot;licitem les seg&uuml;ents dades personals: Nom, Correu electr&ograve;nic, per gestionar la llista de subscripcions, enviar butlletins, promocions i ofertes especials, facilitades per l'usuari en realitzar la subscripci&oacute;. Dins de la web hi ha diversos formularis per activar la subscripci&oacute;. Els butlletins electr&ograve;nics o butlletins s&oacute;n gestionats per Sengrid. L'informo que les dades que ens faciliti estaran ubicats en els servidors de Sengrid (prove&iuml;dor de COPERNIC TECHNOLOGIES S.L.).</li>
<li>Formulari de desc&agrave;rrega: En aquesta web es poden descarregar diferents continguts que s'incorporen peri&ograve;dicament. En aquest cas, sol&middot;licitem les seg&uuml;ents dades personals: Nom, Correu electr&ograve;nic, per activar el formulari de subscripci&oacute;. La seva informaci&oacute; s'utilitza per a les finalitats indicades per als subscriptors.</li>
<li>Formulari de Registre: En aquest cas, sol&middot;licitem les seg&uuml;ents dades personals: Nom, Correu electr&ograve;nic, perqu&egrave; pugui crear un compte en la nostra aplicaci&oacute; https://app.nectios.com/ i tenir acc&eacute;s a les seves funcionalitats.</li>
</ul>
<p>Hi ha altres finalitats per a les quals tractem les seves dades personals:</p>
<ul>
<li>Vetllar pel compliment de les Condicions d'&Uacute;s i de la legislaci&oacute; aplicable. Aix&ograve; pot incloure el desenvolupament d'eines i algoritmes que ajudin a aquest lloc web a garantir la confidencialitat de les dades personals que recull.</li>
<li>Donar suport i millorar els serveis que ofereix aquest lloc web.</li>
<li>Tamb&eacute; es recullen altres dades no identificatives que s'obtenen a trav&eacute;s d'algunes cookies que es descarreguen a l'ordinador de l'usuari en navegar per aquest lloc web, que es detalla en la pol&iacute;tica de cookies.</li>
<li>Gestionar les xarxes socials. COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) pot tenir pres&egrave;ncia a les xarxes socials. El tractament de les dades que es realitzi de les persones que es facin seguidores a les xarxes socials de les p&agrave;gines oficials de https://www.connectingatartup.com, es regir&agrave; per aquest apartat. Aix&iacute; com per a aquelles condicions d'&uacute;s, pol&iacute;tiques de privacitat i normativa d'acc&eacute;s que pertanyin a la xarxa social que procedeixi en cada cas i pr&egrave;viament acceptades per l'usuari de COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios). Tractar&agrave; les seves dades amb la finalitat de gestionar correctament la seva pres&egrave;ncia a la xarxa social, informant sobre activitats, productes o serveis de COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios). Aix&iacute; com per a qualsevol altra finalitat que la normativa de xarxes socials permeti. En cap cas utilitzarem els perfils de seguidors a les xarxes socials per enviar publicitat de forma individual.</li>
</ul>
<p>D'acord amb el que estableix el Reglament General Europeu de Protecci&oacute; de Dades (RGPD) 2016/679, COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) domiciliada a ESPANYA. 12400 Sogorb, Castell&oacute;, ser&agrave; l'encarregada de tractar les dades corresponents als Usuaris del lloc web i subscriptors.</p>
<p>COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios), no ven, lloga ni cedeix dades personals que puguin identificar l'usuari, ni ho far&agrave; en el futur, a tercers sense previ consentiment. No obstant aix&ograve;, en alguns casos es podran realitzar col&middot;laboracions amb altres professionals, en aquests casos, es requerir&agrave; el consentiment dels usuaris informant sobre la identitat del col&middot;laborador i la finalitat de la col&middot;laboraci&oacute;. Sempre es dur&agrave; a terme amb les m&eacute;s estrictes normes de seguretat.</p>
<p>&nbsp;</p>
<h2>Rem&agrave;rqueting</h2>
<p>La funci&oacute; de rem&agrave;rqueting ens permet arribar a les persones que ja han visitat https://nectios.com i associar un p&uacute;blic concret amb un missatge concret. El rem&agrave;rqueting &eacute;s un m&egrave;tode per aconseguir que els usuaris que han visitat el nostre lloc web ho tornin a fer.</p>
<p>Com a usuari de https://app.nectios.com/ us informo que estic recopilant informaci&oacute; per a aquesta funci&oacute; de rem&agrave;rqueting a https://nectios.com.</p>
<p>La informaci&oacute; que recopilem gr&agrave;cies a aquesta funci&oacute; &eacute;s recollida per cookies de Facebook, Google Ads, LinkedIn Ads. Pot con&egrave;ixer les dades que aquestes cookies recullen en les seg&uuml;ents pol&iacute;tiques de privacitat de cada servei:</p>
<ul>
<li>Facebook</li>
<li>Anuncis de Google</li>
<li>Anuncis de LinkedIn</li>
</ul>
<p>Si no voleu que aquestes galetes recopilin la vostra informaci&oacute;, podeu desactivar l'&uacute;s de galetes de Google a trav&eacute;s de Configuraci&oacute; d'anuncis de Google. Tamb&eacute; podeu desactivar l'&uacute;s de galetes per part d'un prove&iuml;dor extern a trav&eacute;s de la p&agrave;gina d'inhabilitaci&oacute; de la Network Advertising Initiative.</p>
<p>Aquest tipus de servei permet interactuar amb xarxes socials o altres plataformes externes directament des de les p&agrave;gines d'aquest lloc web. Les interaccions i informaci&oacute; obtinguda per aquest lloc web estaran sempre subjectes a la configuraci&oacute; de privacitat de l'usuari en cada xarxa social. En el cas que s'instal&middot;li un servei que permeti la interacci&oacute; amb les xarxes socials, &eacute;s possible que, encara que els usuaris no utilitzin el servei, es recullin dades de tr&agrave;nsit web relacionades amb les p&agrave;gines en qu&egrave; s'instal&middot;len.</p>
<h2>Legitimaci&oacute; per al tractament de les seves dades</h2>
<p>La base legal per al tractament de les seves dades &eacute;s: consentiment.</p>
<p>Per contactar o fer comentaris en aquest lloc web, es requereix el consentiment amb aquesta pol&iacute;tica de privacitat.</p>
<p>L'oferta prospectiva o comercial de productes i serveis es basa en el consentiment que se li sol&middot;licita, sense que en cap cas la retirada d'aquest consentiment condicioni l'execuci&oacute; del contracte de subscripci&oacute;.</p>
<p>categoria de dades<br />Les categories de dades que es tracten s&oacute;n dades identificatives. No es processen categories de dades especialment protegides.</p>
<p>Durant quant de temps conservarem les seves dades?<br />Les dades personals proporcionades es conservaran:</p>
<p>A quins destinataris es comunicaran les seves dades?<br />Moltes eines que utilitzem per gestionar les seves dades s&oacute;n contractades per tercers.</p>
<p>Per prestar serveis estrictament necessaris per al desenvolupament de l'activitat, https://app.nectios.com, comparteix dades amb els seg&uuml;ents prove&iuml;dors sota les seves corresponents condicions de privacitat:</p>
<p>Google Analytics: un servei d'anal&iacute;tica web prestat per Google, Inc., una companyia de Delaware l'oficina principal de la qual es troba a 1600 Amphitheatre Parkway, Mountain View (Calif&ograve;rnia), CA 94043, Estats Units ("Google"). Google Analytics utilitza &laquo;cookies&raquo;, que s&oacute;n arxius de text col&middot;locats en el seu ordinador, per ajudar a https://www.connectingstartup.com analitzar com els usuaris utilitzen el lloc web. La informaci&oacute; generada per la cookie sobre el seu &uacute;s de https://connectingstartup.com (inclosa la seva adre&ccedil;a IP) ser&agrave; directament transmesa i emmagatzemada per Google en servidors dels Estats Units.</p>
<p>Email m&agrave;rqueting: Sengrid. M&eacute;s informaci&oacute; a: WWW.SENGRID.COM</p>
<h2>Navegaci&oacute;</h2>
<p>En navegar https://nectios.com es poden recollir dades no identificatives, que poden incloure adreces IP, ubicaci&oacute; geogr&agrave;fica (aproximadament), un registre de com s'utilitzen els serveis i llocs, i altres dades que no es poden utilitzar per identificar l'usuari. Entre les dades no identificatives es troben tamb&eacute; les relacionades amb els seus h&agrave;bits de navegaci&oacute; a trav&eacute;s de serveis de tercers. Aquest lloc web utilitza els seg&uuml;ents serveis d'an&agrave;lisi de tercers:</p>
<ul>
<li>Google Analytics</li>
<li>Facebook a l'interior</li>
<li>P&iacute;xel de Facebook</li>
<li>InsightTag</li>
</ul>
<p>Utilitzem aquesta informaci&oacute; per analitzar tend&egrave;ncies, administrar el lloc, fer un seguiment dels moviments dels usuaris al voltant del lloc i per recopilar informaci&oacute; demogr&agrave;fica sobre la nostra base d'usuaris en el seu conjunt.</p>
<h2>Secret i seguretat de dades</h2>
<p>COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) es compromet a utilitzar i tractar les dades personals dels usuaris, respectant la seva confidencialitat i utilitzant-los d'acord amb la seva finalitat, aix&iacute; com a complir amb la seva obligaci&oacute; de guardar-los i adaptar totes les mesures per evitar la seva alteraci&oacute;, p&egrave;rdua, tractament o acc&eacute;s no autoritzat, d'acord amb el que estableix la normativa vigent de protecci&oacute; de dades.</p>
<p>Aquest lloc web inclou un certificat SSL. &Eacute;s un protocol de seguretat que garanteix que les seves dades viatgen de forma completa i segura, &eacute;s a dir, la transmissi&oacute; de dades entre un servidor web i usuari, i en retroalimentaci&oacute;, est&agrave; totalment encriptada o xifrada. COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) no pot garantir l'absoluta inexpugnabilitat de la xarxa d'Internet i per tant la violaci&oacute; de dades mitjan&ccedil;ant l'acc&eacute;s fraudulent a les mateixes per part de tercers.</p>
<p>Pel que fa a la confidencialitat del tractament, COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) vetllar&agrave; perqu&egrave; qualsevol persona autoritzada per COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) per tractar les dades dels clients (incl&ograve;s el seu personal, col&middot;laboradors i prove&iuml;dors), estar&agrave; sota l'obligaci&oacute; de confidencialitat adequada (ja sigui un deure contractual o legal). Quan es produeixi un incident de seguretat, realitzant COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios), ho notificar&agrave; al Client sense demora indeguda i proporcionar&agrave; informaci&oacute; oportuna relacionada amb l'Incident de Seguretat tal com es conegui o quan sigui raonablement sol&middot;licitat pel Client.</p>
<h2>Exactitud i veracitat de les dades</h2>
<p>Com a usuari, ets l'&uacute;nic responsable de l'exactitud i correcci&oacute; de les dades que envi&iuml;s a https://app.nectios.com, exonerant a COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios), de qualsevol responsabilitat al respecte.</p>
<p>Els usuaris garanteixen i s&oacute;n responsables, en tot cas, de l'exactitud, vig&egrave;ncia i autenticitat de les dades personals facilitades, i es comprometen a mantenir-les degudament actualitzades. L'usuari es compromet a facilitar informaci&oacute; completa i correcta en el formulari de contacte o subscripci&oacute;.</p>
<h2>Acceptaci&oacute; i consentiment</h2>
<p>L'usuari declara haver estat informat de les condicions en mat&egrave;ria de protecci&oacute; de dades de car&agrave;cter personal, acceptant i consentint el tractament dels mateixos per part de COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) en la forma i per a les finalitats indicades en aquesta pol&iacute;tica de privacitat.</p>
<h2>Revocabilitat</h2>
<p>El consentiment prestat, tant per al tractament com per a la cessi&oacute; de les dades dels interessats, &eacute;s revocable en qualsevol moment mitjan&ccedil;ant la notificaci&oacute; de COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) en els termes establerts en aquesta Pol&iacute;tica per a l'exercici dels drets ARCO. Aquesta revocaci&oacute; no tindr&agrave; en cap cas car&agrave;cter retroactiu.</p>
<h2>Canvis en la pol&iacute;tica de privacitat</h2>
<p>COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) es reserva el dret de modificar aquesta pol&iacute;tica per adaptar-la a la nova legislaci&oacute; o jurisprud&egrave;ncia, aix&iacute; com a les pr&agrave;ctiques del sector. En aquests casos, COPERNIC TECHNOLOGIES S.L. (tamb&eacute; Nectios) anunciar&agrave; en aquesta p&agrave;gina els canvis introdu&iuml;ts amb raonable antelaci&oacute; a la seva implementaci&oacute;.</p>
<h2>Missatgeria d'empreses</h2>
<p>D'acord amb la LSSICE, https://app.nectios.com no realitza pr&agrave;ctiques de SPAM, per la qual cosa no envia correus comercials que no hagin estat pr&egrave;viament sol&middot;licitats o autoritzats per l'usuari. En conseq&uuml;&egrave;ncia, en cadascun dels formularis de la web, l'usuari t&eacute; la possibilitat de donar el seu consentiment expr&eacute;s per rebre la newsletter, independentment de la informaci&oacute; comercial espec&iacute;ficament sol&middot;licitada. D'acord amb el que estableix la Llei 34/2002 de Serveis de la Societat de la Informaci&oacute; i de Comer&ccedil; Electr&ograve;nic, https://connectingstartup.com es compromet a no remetre comunicacions de car&agrave;cter comercial sense identificar-les adequadament.</p>
<p>Document revisat el 15-04-2022.</p>
</div>
<div v-if="currentLocale === 'en'">
  <p>Your privacy is important for us. This privacy statement explains what personal data we collect from our users and how we use it. We encourage you to read these terms carefully before providing your personal data on this website. Those over thirteen years of age may register at https://app.nectios.com/ as users without the prior consent of their parents or guardians.</p>
<p>In the case of minors under the age of thirteen, the consent of their parents or guardians is required for the processing of their personal data. In no case will data relating to the professional, economic situation or the privacy of other family members be collected from the minor, without their consent.</p>
<p>If you are under thirteen years of age and have accessed this website without notifying your parents, you should not register as a user.</p>
<p>This website respects and cares for the personal data of users. As a user you should know that your rights are guaranteed. We have made an effort to create a safe and reliable space and that is why we want to share our principles regarding your privacy:</p>
<ul>
<li>We never request personal information unless it is really necessary to provide you with the services you require.</li>
<li>We never share personal information of our users with anyone, except to comply with the law or in case we have your express authorization.</li>
<li>We will never use your personal data for a purpose other than that expressed in this privacy policy.</li>
</ul>
<p>It should be noted that this Privacy Policy could vary based on legislative requirements or self-regulation, so users are advised to visit it periodically. It will be applicable in the event that users decide to fill in a form of any of their contact forms where personal data is collected.</p>
<p>COPERNIC TECHNOLOGIES S.L. (also Nectios) has adapted this website to the requirements of Organic Law 15/1999, of December 13, on the Protection of Personal Data (LOPD), and Royal Decree 1720/2007, of December 21, known as the Development Regulation of the LOPD. It also complies with Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016 regarding the protection of natural persons (RGPD), as well as with Law 34/2002, of July 11, of Services of the Information Society and Electronic Commerce (LSSICE or LSSI).</p>
<p>A efectos de lo previsto en el Reglamento General de Protecci&oacute;n de Datos antes citado, los datos personales que nos env&iacute;es a trav&eacute;s de los formularios de la web, recibir&aacute;n el tratamiento de datos de &ldquo;Usuarios de la web y suscriptores&rdquo;. Para el tratamiento de datos de nuestros usuarios, implementamos todas las medidas t&eacute;cnicas y organizativas de seguridad establecidas en la legislaci&oacute;n vigente.</p>
<h2>Principles we will apply to your personal information</h2>
<p>In the processing of your personal data, we will apply the following principles that comply with the requirements of the new European data protection regulation:</p>
<ul>
<li>Principle of legality, loyalty and transparency: We will always require your consent for the processing of your personal data for one or more specific purposes that we will previously inform you of with absolute transparency.</li>
<li>Data minimization principle: We will only request data that is strictly necessary in relation to the purposes for which we require it. The minimum possible.</li>
<li>Principle of limitation of the conservation period: the data will be kept for no longer than necessary for the purposes of the treatment, depending on the purpose, we will inform you of the corresponding conservation period, in the case of subscriptions, we will periodically review our lists and we will eliminate those records inactive for a considerable time.</li>
<li>Principle of integrity and confidentiality: Your data will be treated in such a way as to guarantee adequate security of personal data and guarantee confidentiality. You should know that we take all necessary precautions to prevent unauthorized access or improper use of our users&rsquo; data by third parties.</li>
</ul>
<h2>How have we obtained your data?</h2>
<p>The personal data that we process at COPERNIC TECHNOLOGIES S.L. (also Nectios) come from:</p>
<ul>
<li>Contact Form</li>
<li>Subscription form</li>
<li>Blog comments</li>
<li>Download form</li>
<li>Registration Form</li>
<li>Webinar registration form</li>
</ul>
<h2>What are your rights when you provide us with your data?</h2>
<p>Any person has the right to obtain confirmation as to whether COPERNIC TECHNOLOGIES S.L. (also Nectios) we are treating personal data that concerns me, or not.</p>
<p>Interested persons have the right to:</p>
<ul>
<li>Request access to personal data relating to the interested party</li>
<li>Request its rectification or deletion</li>
<li>Request the limitation of your treatment</li>
<li>Object to treatment</li>
<li>Request data portability</li>
</ul>
<p>Interested parties may access their personal data, as well as request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected. In certain circumstances, the interested parties may request the limitation of the processing of their data, in which case I will only keep them for the exercise or defense of claims.</p>
<p>In certain circumstances and for reasons related to their particular situation, the interested parties may oppose the processing of their data. COPERNIC TECHNOLOGIES S.L. (also Nectios) will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims. As an interested party, you have the right to receive the personal data that concerns you, that you have provided us and in a structured format, of common use and mechanical reading, and to transmit them to another data controller when:</p>
<ul>
<li>Treatment is based on consent</li>
<li>The data has been provided by the interested person.</li>
<li>The treatment is carried out by automated means.</li>
</ul>
<p>By exercising your right to data portability, you will have the right to have personal data transmitted directly from controller to controller when technically possible.</p>
<p>Interested parties will also have the right to effective judicial protection and to file a claim with the control authority, in this case, the Spanish Data Protection Agency, if they consider that the processing of personal data that concerns them violates the Regulation.</p>
<h2>For what purpose do we treat your personal data?</h2>
<p>When a user connects to this website, for example to comment on a post, send an email to the owner, subscribe or make a contract, they are providing personal information for which COPERNIC TECHNOLOGIES S.L. is responsible. (also Nectios). That information may include personal data such as your IP address, name, physical address, email address, telephone number, and other information. By providing this information, the user gives his consent for his information to be collected, used, managed and stored by https://app.nectios.com/, only as described in the Legal Notice and in this Privacy Policy.</p>
<p>At COPERNIC TECHNOLOGIES S.L. (also Nectios) there are different systems for capturing personal information and I treat the information provided by interested persons with the following purpose for each capture system (forms):</p>
<ul>
<li>Contact form: We request the following personal data: Name, Email, to respond to the requirements of the users of https://nectios.com. For example, we can use this data to respond to your request and respond to any doubts, complaints, comments or concerns you may have regarding the information included on the web, the services provided through the web, the treatment of your personal data, questions regarding the legal texts included on the website, as well as any other queries you may have that are not subject to the contracting conditions.</li>
<li>Content subscription form: In this case, we request the following personal data: Name, Email, to manage the subscription list, send newsletters, promotions and special offers, provided by the user when making the subscription. Within the web there are several forms to activate the subscription. The electronic bulletins or newsletters are managed by Sengrid. I inform you that the data you provide us will be located on the servers of Sengrid (supplier of COPERNIC TECHNOLOGIES S.L.).</li>
<li>Download form: On this website you can download different contents that are incorporated periodically. In this case, we request the following personal data: Name, Email, to activate the subscription form. Your information is used for the purposes indicated for subscribers.</li>
<li>Registration Form: In this case, we request the following personal information: Name, Email, so that you can create an account in our application https://app.nectios.com/ and have access to its functionalities.</li>
</ul>
<p>There are other purposes for which we process your personal data:</p>
<ul>
<li>To ensure compliance with the Terms of Use and applicable law. This may include the development of tools and algorithms that help this website to guarantee the confidentiality of the personal data it collects.</li>
<li>To support and improve the services offered by this website.</li>
<li>Other non-identifying data is also collected that is obtained through some cookies that are downloaded to the user&rsquo;s computer when browsing this website, which is detailed in the cookie policy.</li>
<li>To manage social networks. COPERNIC TECHNOLOGIES S.L. (also Nectios) can have a presence on social networks. The treatment of the data that is carried out of the people who become followers in the social networks of the official pages of https://www.connectingatartup.com, will be governed by this section. As well as for those conditions of use, privacy policies and access regulations that belong to the social network that is appropriate in each case and previously accepted by the user of COPERNIC TECHNOLOGIES S.L. (also Nectios). It will treat your data with the purpose of correctly managing your presence in the social network, informing about activities, products or services of COPERNIC TECHNOLOGIES S.L. (also Nectios). As well as for any other purpose that the regulations of social networks allow. In no case will we use the profiles of followers on social networks to send advertising individually.</li>
</ul>
<p>In accordance with the provisions of the general European data protection regulation (RGPD) 2016/679, COPERNIC TECHNOLOGIES S.L. (also Nectios) domiciled in SPAIN. 12400 Segorbe, Castell&oacute;n, will be responsible for processing the data corresponding to Users of the website and subscribers.</p>
<p>COPERNIC TECHNOLOGIES S.L. (also Nectios), does not sell, rent or transfer personal data that can identify the user, nor will it do so in the future, to third parties without prior consent. However, in some cases collaborations can be made with other professionals, in those cases, consent will be required from users informing about the identity of the collaborator and the purpose of the collaboration. It will always be carried out with the strictest security standards.</p>
<p>&nbsp;</p>
<h2>Remarketing</h2>
<p>The remarketing feature allows us to reach people who have previously visited https://nectios.com and associate a specific audience with a specific message. Remarketing is a method to get users who have visited our site to do so again.</p>
<p>As a user of https://app.nectios.com/ I inform you that I am collecting information for this remarketing function on https://nectios.com.</p>
<p>The information we collect thanks to this function is collected by cookies from Facebook, Google Ads, LinkedIn Ads. You can know the data that these cookies collect in the following privacy policies of each service:</p>
<ul>
<li>Facebook</li>
<li>Google ads</li>
<li>LinkedIn Ads</li>
</ul>
<p>If you do not want your information to be collected by these cookies, you can opt out of Google&rsquo;s use of cookies through Google Ad Settings. You can also opt out of a third party provider&rsquo;s use of cookies through the Network Advertising Initiative&rsquo;s opt-out page.</p>
<p>This type of service allows you to interact with social networks or other external platforms directly from the pages of this website. The interactions and information obtained by this website will always be subject to the user&rsquo;s privacy settings in each social network. In the event that a service is installed that allows interaction with social networks, it is possible that, although users do not use the service, it collects web traffic data related to the pages on which they are installed.</p>
<h2>Legitimation for the treatment of your data</h2>
<p>The legal basis for the treatment of your data is: consent.</p>
<p>To contact or make comments on this website, consent is required with this privacy policy.</p>
<p>The prospective or commercial offer of products and services is based on the consent that is requested, without in any case the withdrawal of this consent conditioning the execution of the subscription contract.</p>
<p>data category<br />The categories of data that are processed are identifying data. Specially protected data categories are not processed.</p>
<p>How long will we keep your data?<br />The personal data provided will be kept:</p>
<p>To which recipients will your data be communicated?<br />Many tools that we use to manage your data are contracted by third parties.</p>
<p>To provide services strictly necessary for the development of the activity, https://app.nectios.com, shares data with the following providers under their corresponding privacy conditions:</p>
<p>Google Analytics: a web analytics service provided by Google, Inc., a Delaware company whose main office is at 1600 Amphitheater Parkway, Mountain View (California), CA 94043, United States (&ldquo;Google&rdquo;). Google Analytics uses &laquo;cookies&raquo;, which are text files placed on your computer, to help https://www.connectingstartup.com analyze how users use the website. The information generated by the cookie about your use of https://connectingstartup.com (including your IP address) will be directly transmitted and stored by Google on servers in the United States.</p>
<p>Email marketing: Sengrid. More information at: WWW.SENGRID.COM</p>
<h2>Navigation</h2>
<p>By browsing https://nectios.com non-identifying data can be collected, which may include IP addresses, geographical location (approximately), a record of how the services and sites are used, and other data that cannot be used to identify the user. Among the non-identifying data are also those related to your browsing habits through third-party services. This website uses the following third-party analysis services:</p>
<ul>
<li>Google analytics</li>
<li>Facebook inside</li>
<li>Facebook-pixel</li>
<li>InsightTag</li>
</ul>
<p>We use this information to analyze trends, administer the site, track users&rsquo; movements around the site, and to gather demographic information about our user base as a whole.</p>
<h2>Secrecy and data security</h2>
<p>COPERNIC TECHNOLOGIES S.L. (also Nectios) undertakes to use and process the personal data of users, respecting their confidentiality and using them in accordance with its purpose, as well as to comply with its obligation to save them and adapt all measures to avoid alteration, loss, treatment or unauthorized access, in accordance with the provisions of current data protection regulations.</p>
<p>This website includes an SSL certificate. It is a security protocol that ensures that your data travels in a complete and secure manner, that is, the transmission of data between a web server and user, and in feedback, is fully encrypted or encrypted. COPERNIC TECHNOLOGIES S.L. (also Nectios) cannot guarantee the absolute impregnability of the Internet network and therefore the violation of data through fraudulent access to them by third parties.</p>
<p>Regarding the confidentiality of the processing, COPERNIC TECHNOLOGIES S.L. (also Nectios) will ensure that any person authorized by COPERNIC TECHNOLOGIES S.L. (also Nectios) to process customer data (including its staff, collaborators and providers), will be under the appropriate obligation of confidentiality (either a contractual or legal duty). When a security incident occurs, realizing COPERNIC TECHNOLOGIES S.L. (also Nectios), shall notify Customer without undue delay and shall provide timely information related to the Security Incident as it becomes known or when reasonably requested by Customer.</p>
<h2>Accuracy and veracity of the data</h2>
<p>As a user, you are solely responsible for the accuracy and correctness of the data you send to https://app.nectios.com, exonerating COPERNIC TECHNOLOGIES S.L. (also Nectios), from any responsibility in this regard.</p>
<p>Users guarantee and are responsible, in any case, for the accuracy, validity and authenticity of the personal data provided, and undertake to keep them duly updated. The user agrees to provide complete and correct information in the contact or subscription form.</p>
<h2>Acceptance and consent</h2>
<p>The user declares to have been informed of the conditions on protection of personal data, accepting and consenting to the treatment thereof by COPERNIC TECHNOLOGIES S.L. (also Nectios) in the manner and for the purposes indicated in this privacy policy.</p>
<h2>Revocability</h2>
<p>The consent given, both for the treatment and for the transfer of the data of the interested parties, is revocable at any time by notifying COPERNIC TECHNOLOGIES S.L. (also Nectios) in the terms established in this Policy for the exercise of ARCO rights. This revocation will in no case be retroactive.</p>
<h2>Changes in the privacy policy</h2>
<p>COPERNIC TECHNOLOGIES S.L. (also Nectios) reserves the right to modify this policy to adapt it to new legislation or jurisprudence, as well as industry practices. In these cases, COPERNIC TECHNOLOGIES S.L. (also Nectios) will announce on this page the changes introduced with reasonable anticipation of their implementation.</p>
<h2>Business couriers</h2>
<p>In accordance with the LSSICE, https://app.nectios.com does not carry out SPAM practices, so it does not send commercial emails that have not been previously requested or authorized by the user. Consequently, in each of the forms on the web, the user has the possibility of giving their express consent to receive the newsletter, regardless of the commercial information specifically requested. In accordance with the provisions of Law 34/2002 on Services of the Information Society and electronic commerce, https://connectingstartup.com undertakes not to send communications of a commercial nature without properly identifying them.</p>
<p>Document revised on 04-15-2022.</p>
</div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import BgHeader from '@/assets/images/placeholders/light/banner-nectios.png';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import Service from '@/config/service-identifiers';
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  components: {
    SafeImg,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, ToastNotificationsMixin],
  data() {
    return {
      isLoading: false,
      community: { name: this.$t('my-nectios.title') },
      legalText: {},
      legalMenu: [],
      selectedText: 'gdpr',
      text: null,

      from: null,
      path: null,
    };
  },
  computed: {
    currentLocale() {
      return this.$route?.query?.locale || this.$store.state.locale.currentLocale;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    email() {
      return `mailto:newsletter${this.community.mainCommunitySlug}.tech`;
    },
    signUpURL() {
      let url = process.env.VUE_APP_SIGN_UP_URL.replace('{subdomain}', this.community.subdomain || 'app').replace(
        '{slug}',
        this.communitySlug,
      );
      url = `${url}?code=${this.community.language}`;
      return url;
    },
    showRegistrationLink() {
      return this.community.accessibility < 2;
    },
    backgroundStyle() {
      const backgrounds = this.community?.customization?.theme?.login?.backgrounds || [];

      if (backgrounds.length) {
        return {
          backgroundImage: `url(${getImageResource(backgrounds[0])}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat !important',
        };
      }

      return null;
    },
    communityLogo() {
      return getImageResource(
        this.community?.customization?.theme?.login?.logo || this.community?.header?.logo_url || this.community?.logoURL || BgHeader,
      );
    },
    brand() {
      return BrandLogo;
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL_COMMUNITY;
    },
    localeInUrl() {
      return this.$route.query.locale;
    },
  },
  watch: {
    communitySlug() {
      this.updateCommunity();
    },
    setLocale(newLocale) {
      this.$store.dispatch('setLocale', newLocale);
    },
  },
  async created() {
    if (this.communitySlug === 'my') {
      this.legalText = {
        terms_conditions: null,
        privacy_policy: null,
        gdpr: null,
        cookies_policy: null,
      };
      this.legalMenu = Object.keys(this.legalText);
      this.selectedText = 'gdpr';
    } else {
      await this.updateCommunity();
      this.selectedText = 'gdpr';
      await this.fetchpolicy();
    }
    if (this.localeInUrl) {
      this.setLocale(this.localeInUrl);
    } else {
      this.setLocale(this.community.language);
    }
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    async fetchpolicy() {
      this.isLoading = true;
      await this.$store.$service[
        Service.BackendClient
      ].get('communityCustomizations/ext', { params: { communitySlug: this.communitySlug } })
        .then((response) => {
          this.legalText = response.data?.community_customizations;
          this.legalMenu = Object.keys(this.legalText).filter((menu) => menu === 'terms_conditions' || menu === 'privacy_policy' || menu === 'gdpr' || menu === 'cookies_policy');
          this.isLoading = false;
        })
        .catch((e) => {
          this.legalText = e.response.data.community_customizations;
          this.legalMenu = Object.keys(this.legalText);
          this.isLoading = false;
        });
    },
    selectedTitle(word) {
      return this.$t(`legal.${word}`);
    },
    getIcon(legal) {
      const menu = {
        terms_conditions: 'FileTextIcon',
        privacy_policy: 'ShieldIcon',
        gdpr: 'LockIcon',
      };
      return menu[legal];
    },
    async updateCommunity() {
      this.isLoading = true;

      const response = await this.$store.$service[Service.BackendClient].get('login', {
        params: { communitySlug: this.communitySlug },
      });
      this.community = response.data.data;
      /* Community colors */
      if (this.community.community_customizations.theme) {
        const customization = this.community.community_customizations.theme;
        if (customization.primaryColor) {
          document.documentElement.style.setProperty(
            '--primary-color',
            customization.primaryColor,
          );
          let hsl = customization.primaryColor;
          hsl = hsl.substring(4, hsl.length - 1);
          hsl = hsl.split(',');
          document.documentElement.style.setProperty(
            '--primary-color-h',
            hsl[0],
          );
          document.documentElement.style.setProperty(
            '--primary-color-s',
            hsl[1],
          );
          document.documentElement.style.setProperty(
            '--primary-color-l',
            hsl[2],
          );
        }
        if (customization.secondaryColor) {
          document.documentElement.style.setProperty(
            '--secondary-color',
            customization.secondaryColor,
          );
          let hslSecondary = customization.secondaryColor;
          hslSecondary = hslSecondary.substring(4, hslSecondary.length - 1);
          hslSecondary = hslSecondary.split(',');
          document.documentElement.style.setProperty(
            '--secondary-color-h',
            hslSecondary[0],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-s',
            hslSecondary[1],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-l',
            hslSecondary[2],
          );
        }
      }
      /* Finish colors */
      /*if (this.community.showChatbot === 1) {
        const chatBot = document.createElement('script');
        chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
        document.body.appendChild(chatBot);
      }*/
      if (this.community.showChatbot === 2) {
        const chatBot = document.createElement('div');
        chatBot.innerHTML = this.$store.getters.currentCollective?.customChatbot;
        document.body.appendChild(chatBot);
      }

      this.isLoading = false;
    },
    setLocale(locale) {
      this.$store.dispatch('setLocale', locale);
    },
    translateTranslationTable,
  },
};
</script>
